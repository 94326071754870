const menu = [
  {
    prop: "Homepage",
    name: "首页",
    path: "/homepage",
    icon: "home",
    children: [],
  },
  {
    prop: "SystemConfiguration",
    name: "系统配置",
    path: "/sys",
    icon: "setting",
    children: [
      {
        prop: "SysApp",
        name: "应用信息",
        path: "/app",
        icon: "",
        children: [],
      },
    ],
  },
  {
    prop: "UserManagementSystem",
    name: "用户中心",
    path: "/ums",
    icon: "user",
    children: [
      {
        prop: "UmsOrganization",
        name: "组织架构",
        path: "/organization",
        icon: "",
        children: [],
      },
      {
        prop: "UmsStaff",
        name: "员工信息",
        path: "/staff",
        icon: "",
        children: [],
      },
      {
        prop: "UmsUser",
        name: "系统用户",
        path: "/user",
        icon: "",
        children: [],
      },
    ],
  },
  {
    prop: "PlatformManagementSystem",
    name: "平台管理",
    path: "/pms",
    icon: "desktop",
    children: [
      {
        prop: "PmsErp",
        name: "揽越信息",
        path: "/erp",
        icon: "",
        children: [],
      },
      // {
      //   prop: "PmsMeadjohnson",
      //   name: "美赞臣信息",
      //   path: "/meadjohnson",
      //   icon: "",
      //   children: [],
      // },
      // {
      //   prop: "PmsDanone",
      //   name: "达能信息",
      //   path: "/danone",
      //   icon: "",
      //   children: [],
      // },
    ],
  },
  {
    prop: "HealthManagementSystem",
    name: "健康系统",
    path: "/hms",
    icon: "heart",
    children: [
      {
        prop: "HmsCheckinStaff",
        name: "员工体温记录",
        path: "/checkin-staff",
        icon: "",
        children: [],
      },
      {
        prop: "HmsCheckinTenant",
        name: "租户体温记录",
        path: "/checkin-tenant",
        icon: "",
        children: [],
      },
      {
        prop: "HmsCheckinVisitor",
        name: "访客体温记录",
        path: "/checkin-visitor",
        icon: "",
        children: [],
      },
      {
        prop: "HmsDisinfection",
        name: "消杀上传记录",
        path: "/disinfection",
        icon: "",
        children: [],
      },
      {
        prop: "HmsNucleicAcid",
        name: "核检上传记录",
        path: "/nucleic-acid",
        icon: "",
        children: [],
      },
    ],
  },
  {
    prop: "DistributionManagementSystem",
    name: "分销系统",
    path: "/dms",
    icon: "money-collect",
    children: [
      {
        prop: "DmsActivity",
        name: "销售活动",
        path: "/activity",
        icon: "",
        children: [],
      },
    ],
  },
];

export default menu;
