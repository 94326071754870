<template>
	<a-layout class="main-layout">
		<a-layout-header class="main-header">
			<div class="logo">
				<img src="@/assets/logo-white.png" width="150" height="60" />
			</div>
			<a-menu theme="dark" mode="horizontal" :style="{ lineHeight: '64px' }" @click="
          (event) => {
            $router.push({
              name: event.key,
            });
          }
        ">
				<template v-for="item in menuList">
					<a-sub-menu v-if="item.children.length > 0" :key="item.prop">
						<template slot="title">
							<a-icon v-if="item.icon" :type="item.icon" />
							<span>{{ item.name }}</span>
						</template>
						<template v-for="citem in item.children">
							<a-menu-item :key="citem.prop" :title="citem.name">
								<a-icon v-if="citem.icon" :type="citem.icon" />
								<span>{{ citem.name }}</span>
							</a-menu-item>
						</template>
					</a-sub-menu>
					<a-menu-item v-else :key="item.prop" :title="item.name">
						<a-icon v-if="item.icon" :type="item.icon" />
						<span>{{ item.name }}</span>
					</a-menu-item>
				</template>
			</a-menu>
		</a-layout-header>
		<a-layout-content class="main-content">
			<a-layout class="sub-layout">
				<a-row class="account">
					<a-col :span="12">
						<a-space>
							<span>Hi,{{ account.username }}</span>
							<a-button type="link" @click="handleAccountLogout">退出登录</a-button>
						</a-space>
					</a-col>
					<a-col :span="12" style="text-align: right">
						<span>{{appVersionTypeCN}} {{appVersionNumber}}</span>
					</a-col>
				</a-row>
				<a-layout-content class="sub-content">
					<router-view />
				</a-layout-content>
			</a-layout>
		</a-layout-content>
	</a-layout>
</template>

<script>
	import Menu from "./menu.js";
	import {
		mapState
	} from "vuex";

	export default {
		name: "Layout",
		data() {
			return {
				appVersionTypeCN: process.env.VUE_APP_VERSION_TYPE_CN,
				appVersionNumber: process.env.VUE_APP_VERSION_NUMBER,
				menuList: [],
				currentMenu: {},
				subMenuList: [],
				tabsActiveKey: "",
				current: ["mail"],
			};
		},
		computed: {
			...mapState({
				account: (state) => state.account,
				erpList: (state) => state.erpList,
				erpActive: (state) => state.erpActive,
			}),
		},
		watch: {},
		mounted: function() {
			this.menuList = Menu;
		},
		methods: {
			handleAccountLogout: function() {
				const _this = this;
				this.$confirm({
					title: "是否确定要退出此账号?",
					okText: "确定",
					okType: "danger",
					cancelText: "取消",
					onOk() {
						_this.$store.dispatch("removeAccount");
						_this.$router.replace({
							name: "Login",
						});
					},
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.main-layout {
		height: 100%;

		.main-header {
			position: fixed;
			width: 100%;
			height: 64px;
			z-index: 999;

			.logo {
				width: 200px;
				float: left;
			}
		}

		.main-content {
			margin: 64px 0 20px 0;
			padding: 0 50px;
			width: 100%;
			height: 100%;
			background: #f0f2f5;
		}
	}

	.sub-layout {
		width: 100%;
		height: 100%;

		.account {
			background: #fff;
			width: 100%;
			height: 48px;
			line-height: 48px;
			padding: 0 20px;
			border-bottom: 1px solid #e8e8e8;
		}

		.sub-content {
			background: #fff;
			padding: 20px;
			height: 100%;
			overflow: auto;
		}
	}
</style>
